<!--
 * @Author: your name
 * @Date: 2020-12-11 10:19:32
 * @LastEditTime: 2021-02-05 17:55:07
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \scweb\src\views\service\outlets\index.vue
-->
<template>
<div style="width:100%" >
    <div class="wrap-box" >
        <el-select v-model="month" @change="changes" placeholder="请选择月份" style="width:250px;margin:20px 0px ;float:left;">
            <el-option v-for="(item,index) in selectMonthList" :key="index" :label="item" :value="item"></el-option>
        </el-select>
        <div class="table-title-box">
            <div class="table-title-bar">
            <div class="table-label"></div>
            <div class="table-label">费用类型</div>
            <div class="table-label">费用金额（元）</div>
            </div>
            <div class="table-title-bar" >
            <div class="table-label">1</div>
            <div class="table-label" >核销券消费</div>
            <div class="table-label" v-text="items['codeAmount']"></div>
            </div>
            <div class="table-title-bar" >
            <div class="table-label">2</div>
            <div class="table-label" >实名发放消费</div>
            <div class="table-label" v-text="items['readNameAmount']"></div>
            </div>
            <div class="table-title-bar" >
            <div class="table-label">3</div>
            <div class="table-label" >直接发放消费</div>
            <div class="table-label" v-text="items['flingAmount']"></div>
            </div>
            <div class="table-title-bar" >
            <div class="table-label">4</div>
            <div class="table-label" >折扣券消费</div>
            <div class="table-label" v-text="items['voucherAmount']"></div>
            </div>
            <div class="table-title-bar" >
            <div class="table-label" style="color:rgb(45,140,240);font-weight:bold" >总金额/元</div>
            <div class="table-label" style="color:rgb(45,140,240);font-weight:bold"  v-text="items['totalAmount']"></div>
            </div>
            
        </div>
    </div>
    <el-row class="wrap-box" :gutter="40">
        <el-col :span="11">
            <div id="pie-chart" class="chart-box" ></div>
        </el-col>
        <el-col :span="11">
            <div id="line-chart" class="chart-box"></div>
        </el-col>
    </el-row>
</div>

    
</template>
<script>
import { closeCount } from '@/api/clearing'
import * as echarts from 'echarts';
var myChart1 = null
var myChart2 = null

export default {
    name:'account',
    components:{
    },
    data(){
        return {
          month:'',
          list:[],
          selectMonthList:[],
          items:{},
        }
    },
    mounted(){
        
        this.getList();
        

    },
    methods:{
        changes(e){
            this.items = this.list.filter(v => v.countDate == String(e))[0];
            console.log(this.items)
            let arr = [
                {value: this.items['codeAmount'], name: '核销券消费'},
                {value: this.items['readNameAmount'], name: '实名发放消费'},
                {value: this.items['flingAmount'], name: '直接发放消费'},
                {value: this.items['voucherAmount'], name: '折扣券消费'},
            ]
            this.pieChart(arr)
            
        },
        getList(){
            let that = this;
            let nowDate = new Date();
            let year = nowDate.getFullYear()
            let month = nowDate.getMonth() + 1 < 10 ? "0" + (nowDate.getMonth() + 1) : nowDate.getMonth() + 1

            closeCount().
            then((response)=>{
                let datas = response.data
                that.list = datas
                that.selectMonthList = datas.map(v=>v.countDate)
                that.month = year+ '-' +month;
                let arr = [
                    {
                        name: '核销券消费',
                        type: 'line',
                        stack: '总量',
                        data: datas.map(v=>v.codeAmount)
                    },
                    {
                        name: '实名发放消费',
                        type: 'line',
                        stack: '总量',
                        data: datas.map(v=>v.readNameAmount)
                    },
                    {
                        name: '直接发放消费',
                        type: 'line',
                        stack: '总量',
                        data: datas.map(v=>v.flingAmount)
                    },
                    {
                        name: '折扣券消费',
                        type: 'line',
                        stack: '总量',
                        data: datas.map(v=>v.voucherAmount)
                    },
                ]
                this.lineChart(that.selectMonthList,arr)
                that.changes(that.month)
            })
        },
        pieChart(arr){
            console.log(arr);
            myChart1 = echarts.init(document.getElementById('pie-chart'));
            // 绘制图表
            myChart1.setOption({
                title: {
                    text: '',
                    subtext: '',
                    left: 'center'
                },
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    orient: 'vertical',
                    left: 'right',
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    top: '30%',
                    containLabel: true
                },
                series: [
                    {
                        name: '月度数据',
                        type: 'pie',
                        radius: '55%',
                        data: arr,
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }
                ]
            });
        },
        lineChart(monthList,arr){
            let that = this;
            myChart2 = echarts.init(document.getElementById('line-chart'));
            // 绘制图表
            myChart2.setOption({
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: monthList
                },
                yAxis: {
                    type: 'value'
                },
                grid: {
                    left: '10%',
                    right: '10%',
                    bottom: '3%',
                    top: '22%',
                    containLabel: true
                },
                legend: {
                    orient: 'vertical',
                    left: 'right',
                },
                series: arr
            });
        }
    },
}
</script>
<style lang="scss" scoped>
@import "@/common/scss/base";
.wrap-box{
    width:100%;
    box-sizing: border-box;
    padding:0px 40px;
}
.table-title-box{
    position: relative;
    display: inline-block;
  border-bottom:1px solid #b4b4b4;
  width:100%;
  margin:0px;
}
.table-title-bar:first-child{
  background-color:#cecece;
}
.table-title-bar{
  display: inline-flex;
  align-items: flex-start; 
  text-align: left;
  width:100%;
  margin:0 auto ;
  color:#333333;
  flex-direction: row;
  border-top:1px solid #b4b4b4;
  border-right:1px solid #b4b4b4;
  height: 40px;
  float:left;
  line-height: 40px;
}
.table-input{
  width:100%;
  margin:0;
  border-radius: 0px;
}
.table-label{
  height: 100%;
  padding:0px 0;
  border-left:1px solid #b4b4b4;
  width:100%;
  text-align: center;
}
.table-label:first-child{
  width:30%;
}

.chart-box{
    width:100%;
    height:550px;
    margin:10px 0px 10px 45px;
    box-sizing: border-box;
}
</style>